import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import Link from "next/link";

import FooterLinks from "@/footer/FooterLinks";
import FOOTER_DATA from "@/model/FooterData";

import CopyrightOutlinedIcon from "@mui/icons-material/CopyrightOutlined";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

type FooterProps = {
	contactEmail: string;
};

export function Footer(props: FooterProps) {
	return (
		<footer>
			<div className="mx-auto max-w-screen-4xl p-8 grid grid-cols-2 grid-rows-2 lg:grid-cols-4">
				<FooterLinks {...FOOTER_DATA[0]} />
				<FooterLinks {...FOOTER_DATA[1]} />
				<FooterLinks {...FOOTER_DATA[2]} />
				<FooterLinks {...FOOTER_DATA[3]} />
				<div className="col-span-2 py-4 xl:h-auto">
					<ul className="list-none space-y-2 xl:text-lg">
						<li className="text-lg font-bold">Terhubung dengan kami</li>
						<li className="flex items-center gap-3 text-gray-ipbt hover:underline text-sm font-semibold">
							<PhoneOutlinedIcon className="text-[1.5rem] text-blue-ipbt sm:text-[2rem] xl:text-[2vw]" />{" "}
							(+62) 251 838 2223/ 837 2400
						</li>
						<li className="flex items-center gap-3 text-gray-ipbt hover:underline text-sm font-semibold">
							<RoomOutlinedIcon className="text-[1.5rem] text-blue-ipbt sm:text-[2rem] xl:text-[2vw]" />
							Jalan Taman Kencana nomor 3, Babakan, Bogor Tengah, Bogor
							Jalan Taman Kencana nomor 3, Babakan, Bogor Tengah, Bogor
						</li>
						<li className="flex items-center gap-3 text-gray-ipbt hover:underline text-sm font-semibold">
							<EmailOutlinedIcon className="text-[1.5rem] text-blue-ipbt sm:text-[2rem] xl:text-[2vw]" />
							{props.contactEmail}
						</li>
					</ul>
				</div>
			</div>
			<div className="flex justify-between bg-gray-100 text-center">
				<div className="mx-auto w-full max-w-screen-4xl py-8 pb-24 lg:pb-8 flex items-center justify-center gap-2 text-gray-ipbt">
					<div className="flex items-center gap-2">
						<CopyrightOutlinedIcon className="size-6" />
						<p className="text-sm lg:text-base font-semibold">
							IPB Training {new Date().getFullYear()}
						</p>
					</div>
					<span>-</span>
					<div className="flex items-center gap-2 text-gray-ipbt">
						<Link href="https://www.facebook.com/ipbtrainingcom/">
							<FacebookIcon className="size-6 lg:text-8" />
						</Link>
						<Link href="https://www.instagram.com/ipbtraining/">
							<InstagramIcon className="size-6 lg:text-8" />
						</Link>
						<Link href="https://twitter.com/IPB_Training">
							<TwitterIcon className="size-6 lg:text-8" />
						</Link>
					</div>
				</div>
			</div>
		</footer>
	);
}
