type FooterLinkItem = {
	url: string;
	label: string;
};

type FooterLinks = {
	items?: FooterLinkItem[];
	title?: string;
};

export default function FooterLinks({ items = [], ...props }: FooterLinks) {
	return (
		<div className="py-4">
			<ul className="list-none space-y-2 whitespace-nowrap xl:text-lg">
				<li className="font-bold">{props.title}</li>
				{items.map((item) => (
					<li key={item.url}>
						<a
							href={item.url}
							className="text-gray-ipbt hover:underline text-sm font-semibold"
						>
							{item.label}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
}
