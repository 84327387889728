import Link from "next/link";
import React from "react";

import { Button } from "@/components/button/Button";
import { MainLayout } from "@/layout/MainLayout";

export default function ErrorPage() {
	return (
		<MainLayout
			meta={{
				title: "Error",
				description: "Halaman tidak ditemukan",
			}}
		>
			<div className="flex h-screen w-full items-center justify-center overflow-hidden">
				<div className="text-center">
					<h1 className=" -mb-6 text-10xl font-semibold text-blue-ipbt xl:text-[8vw]">
						404
					</h1>
					<h2 className="mb-2 text-5xl font-bold text-slate-700 xl:text-[2.4vw]">
						Page not found
					</h2>
					<p className="mb-6 text-xl text-slate-700 xl:text-[1.2vw]">
						Maaf, kami tidak dapat menemukan halaman yang Anda cari
					</p>
					<Link href="/">
						<Button
							type="button"
							variant="blue"
							text="Back Home"
							className="rounded-full! px-10!"
						/>
					</Link>
				</div>
			</div>
		</MainLayout>
	);
}
