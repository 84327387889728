const FOOTER_DATA = [
	{
		title: "Informasi Entitas",
		items: [
			{ url: "/about", label: "Tentang kami" },
			{ url: "https://careers.blst.co.id/", label: "Career" },
			{ url: "https://blog.ipbtraining.com/", label: "Blog" },
		],
	},
	{
		title: "Legal",
		items: [
			{ url: "/file/IZIN_LEMBAGA.pdf", label: "Izin Lembaga" },
		],
	},
	{
		title: "Link Terkait",
		items: [
			{ url: "https://www.ipb.ac.id/beranda-id/", label: "IPB University" },
			{ url: "https://blst.co.id/", label: "PT BLST" },
			{ url: "https://test.ipbtraining.com/", label: "LMS" },
		],
	},
	{
		title: "Mengapa Kami?",
		items: [
			{ url: "/company-profile", label: "Company profile" },
			{ url: "/our-client", label: "Pelanggan kami" },
			{ url: "/katalog", label: "Katalog" },
		],
	},
];

export default FOOTER_DATA;
