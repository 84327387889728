import GmailIcon from "@/public/img/logo/gmail.png";
import WhatsappIcon from "@/public/img/logo/whatsapp.png";
import { createWhatsappChatLink } from "@/utils/social";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { type ReactNode, useState } from "react";

type HelpButtonProps = {
	email?: string;
	phone?: string;
};

type HelpItem = {
	icon: ReactNode;
	label: string;
	href: string;
};

const HELP_DEFAULTS = {
	whatsapp: "6285216963170",
	gmail: "contact@ipbtraining.com",
	ticket: "/ticket/app/tickets",
};

export function HelpButton(props: HelpButtonProps) {
	const [show, setShow] = useState(false);
	const HELP_ITEMS: HelpItem[] = [
		{
			icon: <Image src={WhatsappIcon} alt="Whatsapp" width={32} height={32} />,
			label: "Chat WhatsApp",
			href: createWhatsappChatLink(props.phone ?? HELP_DEFAULTS.whatsapp),
		},
		{
			icon: <Image src={GmailIcon} alt="Gmail" width={32} height={32} />,
			label: "Kirim Email",
			href: `mailto:${props.email ?? HELP_DEFAULTS.gmail}`,
		},
		{
			icon: <ConfirmationNumberIcon className="text-gray-700 size-8" />,
			label: "Buat Tiket",
			href: HELP_DEFAULTS.ticket,
		},
	];

	function handleClick() {
		setShow((prev) => !prev);
	}

	return (
		<div className="fixed right-4 bottom-4 lg:right-6 lg:bottom-6 z-50">
			<div
				className={classNames(
					"flex flex-col items-end gap-2 mb-2 text-gray-800 transition-all duration-100",
					{
						"visible opacity-100 translate-y-0": show,
						"pointer-events-none invisible opacity-0 translate-y-4": !show,
					},
				)}
			>
				{HELP_ITEMS.map((item) => (
					<Link key={item.label} href={item.href}>
						<button
							type="button"
							className="flex font-semibold gap-2 items-center px-4 py-3 bg-white rounded-3xl shadow-md border border-gray-300 cursor-pointer"
						>
							{item.icon}
							{item.label}
						</button>
					</Link>
				))}
			</div>
			<button
				type="button"
				className="ml-auto flex font-bold gap-2 items-center px-4 py-3 bg-white rounded-2xl drop-shadow-xl border border-gray-300 cursor-pointer"
				onClick={handleClick}
			>
				<Image
					src="/assets/images/chat-bot.png"
					alt="Help"
					width={36}
					height={36}
				/>
				Bantuan
			</button>
		</div>
	);
}
