import type { PropsWithChildren } from "react";
import { HelpButton } from "@/components/button/HelpButton";
import { Navbar } from "@/components/navigation/Navbar";
import SlateFooter from "@/footer/SlateFooter";
import { Footer } from "@/layout/Footer";
import Meta, { type MetaProps } from "@/layout/Meta";

type MainLayoutProps = PropsWithChildren<{
	meta: MetaProps;
	contact?: "technical" | "ldp" | "ila";
	footer?: "default" | "slate";
	help?: {
		email?: string;
		phone?: string;
	};
}>;

const EMAILS = {
	technical: "contact@ipbtraining.com",
	ldp: "contact@ipbtraining.com",
	ila: "ila@ipbtraining.com",
};

export function MainLayout({ footer = "default", ...props }: MainLayoutProps) {
	return (
		<>
			<Meta {...props.meta} />
			<Navbar />
			<HelpButton {...props.help} />
			{props.children}
			{footer === "default" && props.contact !== undefined && (
				<Footer contactEmail={EMAILS[props.contact]} />
			)}
			{footer === "slate" && <SlateFooter />}
		</>
	);
}
