import Image from "next/image";

export default function SlateFooter() {
	return (
		<div className="flex fixed bottom-0 h-[20vh] w-full flex-row items-center justify-center bg-slate-100">
			<div className="flex w-full flex-col items-center">
				<Image
					src={"/logo-ipbtraining-landscape.webp"}
					sizes="100vw"
					style={{
						width: "15%",
						height: "auto",
					}}
					width={300}
					height={74}
					alt="Logo IPB Training"
					className=""
				/>
				<h1 className="text-[0.8rem] text-gray-ipbt">
					© 2023 - 2024 IT PT BLST
				</h1>
			</div>
		</div>
	);
}
