export function createWhatsappChatLink(number: string, text = "") {
	const baseUrl = "https://api.whatsapp.com/send";
	const url = new URL(baseUrl);
	// strip + from number
	url.searchParams.set("phone", number?.replace("+", ""));
	url.searchParams.set("type", "phone_number");
	url.searchParams.set("app_absent", "0");
	if (text) {
		url.searchParams.set("text", text);
	}
	return url.toString();
}
